/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.img-bigger {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;

  &:hover {
    transform: scale(5.5);
    -webkit-transform: scale(5.5);
    -moz-transform: scale(5.5);
    -ms-transform: scale(5.5);
    -o-transform: scale(5.5);
    transform: scale(5.5);
    -webkit-box-shadow: 3px 3px 5px #000;
    -moz-box-shadow: 3px 3px 5px #000;
    box-shadow: 3px 3px 5px #000;
    -webkit-backface-visibility: hidden;
  }
}
